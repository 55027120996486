import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {IwColumn} from '@sam-base/core/column';

@IwRestEntity('sapaimode')
export class Sapaimode {
    @IwRestEntityId() @IwColumn({
        name: 'code',
        index: 0
    }) public mpaId?: string;
    @IwColumn({
        name: 'titre',
        index: 1
    }) public titre?: string;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public payType?: number;
    public dtatype?: number;
    public dtadelai?: number;
    public banque?: string;
    public banqueClr?: string;
    public banqueCpt?: string;
    public banqueSwi?: string;
    public iban?: string;
    public dtaiban?: string;
    public dtadonid?: string;
    public compte?: string;
    public compte2?: string;
    public compte3?: string;
    public actif?: boolean;
    public userId?: string;
    public pmData?: string;
}
