export enum IwDecompteName {
    /** From raplst9.sc2 */
    HeuresTravaillees = 'rapls9',
    Contracts = 'mislst4',
    DecLPP = 'ppemprh',
    Debuts = 'mislst2',
    RapManq = 'pprapmanq',
    EmpDoc = 'ppempdocslt',
    Swissstaffing = 'swissstaffing',
    sitdebiteur = 'sitdebiteur',
    baldebiteur = 'baldebiteur',
    caclient = 'caclient',
    ActiveMissions = 'active_missions',
    NewClient = 'new_client',
    Calendar = 'calendar',
    WeeklyTimsheets = 'rappreprt',
    Rloc = 'rapport_location_service',
    RapportAnnuel = 'plac_fixe_rap_annuel',
    HeuresProduites = 'heures_produites',
    MissionHistory = 'mission_history',
    ReleveComptesDebiteurs = 'releveComptesDebiteurs',
    SituationDebiteur = 'sitdebiteur',
    CertificatTravail = 'cert_travail',
    RecapMis = 'RecapMis',
    Abs = 'Abs',
    Acolist = 'Acolist',
    Journaux = 'Journaux',
    Gsrecapannuel = 'Gsrecapannuel',
    Afrecapannuel = 'Afrecapannuel',
    Decomptegs = 'Decomptegs',
    Decomptecct = 'Decomptecct',
    Paiements = 'Paiements',
    Definitionsalaire = 'definitionsalaire',
    permis = 'permis',
    lppnonpayee = 'lppnonpayee',
    decompteentreprise = 'decompteentreprise',
    listedossieremployes = 'listedossieremployes',
    gsannuelrevisions = 'gsannuelrevisions',
    Suva = 'Suva',
    Margin = 'Margin',
    Timesheet = 'Timesheet',
    EcheancePermis = 'Echeances_permis'
}
