export enum Pointtype {
    interijob = '1',
}

export enum Pointmode {
    automatique = '1', manuel = '2',
}

export enum Ijrapsm {
    pointeuseIj = '1', rapport = '2', aucun = '3',
}

export enum Icc2ijmod {
    aucune = 1, pdf, pdfDemandeSignature,
}
