import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator} from '../../core/rest-api';

@IwRestEntity('gesaexeview')
export class GesaexeView {
    @IwRestEntityId() @IwColumn({name: 'annee', index: 0}) public cannee?: string;
    public larc?: boolean;
    @IwColumn({
        name: 'status',
        index: 1,
        type: 'status',
        valueFormat: 'gesaexe_',
        statusQuery: [false, true],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public larcStatus?: string;
    public active?: boolean;
}
